import { clientErrors } from './messages';
import moment from 'moment';
const IMMICARD_PATTERN = new RegExp(/^[a-zA-Z]{3}[0-9]{6}$/);
const IDV_NAMES_STRICT_PATTERN = new RegExp(/(?=^.{1,40}$)^[a-zA-Z\s]+([\'\.\-]?[a-zA-Z\s]*)+([\'\.\-]?[a-zA-Z\s]*)?$/);
const IDV_FAMILY_NAME_STRICT_PATTERN = new RegExp(/(?=^.{1,50}$)^[a-zA-Z\s]+([\'\.\-]?[a-zA-Z\s]*)+([\'\.\-]?[a-zA-Z\s]*)?$/);
const IDV_NAMES_3_CONSECUTIVE_DUPLICATE_CHAR = new RegExp(/^(?!.*(.)\1\1)[a-zA-Z\s]+([\'\.\-]?[a-zA-Z\s]*)+([\'\.\-]?[a-zA-Z\s]*)?$/);
const INVALID_SEQEUNCE_PATTERN = new RegExp(/.*(--)+|(\.\.)+|(\.-)+|(-\.)+|(\.')+|('\.)+|('')+|(-')+|('-)+|(\s-)+|(-\s)+|('\s)+|(\s')+.*/);
const INVALID_TOKEN_PATTERN = new RegExp(
  /\b(MRS|MS|MISS|MS|ACN|ABN|DOB|INc|LTD|MR|PL|PTY|REP|SP|INCORPORATED|(NO\sLIABILITY)|PROPRIETARY|(ON\sBEHALF)|PASSWORD|XXX)\b/i
);
const ACT_DOC_NUM = new RegExp(/^[0-9]{1,10}$/);
const NT_DOC_NUM = new RegExp(/^[0-9]{1,10}$/);
const VIC_DOC_NUM = new RegExp(/^[0-9]{1,10}$/);
const NSW_DOC_NUM = new RegExp(/^[0-9A-Za-z]{6,8}$/);
const TAS_DOC_NUM = new RegExp(/^[0-9A-Za-z]{6,8}$/);
const QLD_DOC_NUM = new RegExp(/^[0-9]{8,9}$/);
const SA_DOC_NUM = new RegExp(/^[0-9A-Za-z]{6}$/);
const WA_DOC_NUM = new RegExp(/^[0-9]{7}$/);
const QLD_CARD_NUM = /^[a-zA-Z0-9]{10}$/;
const TAS_CARD_NUM = /^[a-zA-Z0-9]{9}$/;
const NSW_CARD_NUM = /^[0-9]{10}$/;
const SA_CARD_NUM = /^[a-zA-Z0-9]{9}$/;
const ACT_CARD_NUM = /^[a-zA-Z0-9]{10}$/;
const NT_CARD_NUM = /^[0-9]{6,8}$/;
const VIC_CARD_NUM = /^[a-zA-Z]{1}[0-9]{7}$/;
const WA_CARD_NUM = /^[a-zA-Z0-9]{8,10}$/;

const AUS_PASSPORT_NUM = new RegExp(/^[a-zA-Z]{1,2}[0-9]{7}$/);
const INT_PASSPORT_NUM = new RegExp(/^[a-zA-Z0-9]{0,14}$/);

const SKIP_NAMES_RULES = new RegExp(/^[a-zA-Z0-9'-]+([ -][a-zA-Z0-9'-\s]+)*$/);

const INVALID_ECOMM_PATTERN = new RegExp(/(?=^.{1,40}$)^(?!(MR|MRS|MS|MISS|-)?( ))(([a-zA-Z]['-])?(?!\w*(?: MRS | MS | ACN | ABN | DOB | INC | LTD | MR | PL | PTY | REP | SP |INCORPORATED|NO LIABILITY|PROPRIETARY|ON BEHALF|PASSWORD|XXX|  ))[a-zA-Z ])*(?<!\w*(?:PTYLTD))$/i);
const INVALID_FAMILY_NAME_ECOMM_PATTERN = new RegExp(/(?=^.{1,50}$)^(?!(MR|MRS|MS|MISS|-)?( ))(([a-zA-Z]['-])?(?!\w*(?: MRS | MS | ACN | ABN | DOB | INC | LTD | MR | PL | PTY | REP | SP |INCORPORATED|NO LIABILITY|PROPRIETARY|ON BEHALF|PASSWORD|XXX|  ))[a-zA-Z ])*(?<!\w*(?:PTYLTD))$/i);
const DRIVER_LICENECE = 'Driver Licence';
const AUS_PASSPORT = 'Australian Passport';
const INT_PASSPORT = 'International Passport';
const MEDICARE_CARD = 'Medicare Card';
const IMMI_CARD = 'ImmiCard';

const validations = (name, value, values, flag,skipValidation=true) => {
  switch (name) {
    case 'firstName':
      return validateName(name,value,"GN",40,flag,skipValidation)
    case 'middleName':
      return validateName(name,value,"MN",40, flag,skipValidation)
    case 'lastName':
      return validateName(name,value,"FN",50, flag,skipValidation)
    case 'familyName':
      return validateName(name,value,"FN",50, flag,skipValidation)
    case 'dob':
      return validateDOB(value)
    case 'idType':
      return checkForValue(value,name)
    case 'stateOfIssue':
      return checkForValue(value,name)
    case 'licenceNumber':
      return validateDocNumber(value, values, name, flag)
    case 'cardNumber':
      return validateDocNumber(value, values,name, flag)
    case 'expiryDate':
      return validateDOE(value,flag,values)
    case 'documentNumber':
      return validateDocNumber(value, values,name, flag)
    case 'cardColor':
      return checkForValue(value,name)
    case 'issuingCountry':
      return checkForValue(value,name)
    case 'individualReferenceNumber':
      return positionNumber(value)
    case 'validNames':
      return validNamesMatch(values, flag)
    case 'date':
      return isValidDate(value)
    case 'isEmpty':
      return isEmpty(value)
    default:
      break;
  }
};

const isEmpty = (value) => {
  //Remove regex condition (|| /\s/.test(value)) for idtype clear error issue
  let valueIsEmpty = value === undefined || value === null || value === '' || value.trim() === '';
  return valueIsEmpty;
}

const namePatternValidate = (value,name) => {
  return name=='familyName' ? INVALID_FAMILY_NAME_ECOMM_PATTERN.test(value) : INVALID_ECOMM_PATTERN.test(value);
}

const validateName = (name, valueName, errKey, length, isSingleName, skipValidation) => {
  let value = valueName.toUpperCase(); 
  let errors = {};
  const isMiddleName = name === "middleName" ? true : false;
  if (skipValidation) {
    if (isMiddleName) {
      if (value.length !== 0) {
        if (value.length < 1 || value.length > length) {
          errors.code = name+'_invalid_length'
        } else if (!SKIP_NAMES_RULES.test(value)) {
          errors.code = 'New_Name_invalid'
        }
      }
    } else {
      if (isEmpty(value) && !isMiddleName) {
        errors.code = 'Required_Field'
      } else if ((value.length < 1 || value.length > length)) {
        errors.code = isSingleName ? 'Name_invalid_length': name+'_invalid_length'
      } else if (!SKIP_NAMES_RULES.test(value)) {
        errors.code = 'New_Name_invalid'
      }
    }
  } else {
    if (isMiddleName) {
      if (value.length !== 0) {
        if (value.length < 1 || value.length > length) {
          errors.code = name+'_invalid_length'
        } else if (validateAllNames(value,isMiddleName, name) && !validateNameDuplicateChar(value,isMiddleName)) {
          errors.code = 'Name_invalid_Duplicate'
        } else if (!validateAllNames(value,isMiddleName,name) || !namePatternValidate(value,name)) {
          errors.code = 'Name_invalid'
        }
      }
    } else {
      if (isEmpty(value) && !isMiddleName) {
        errors.code = 'Required_Field'
      } else if ((value.length < 1 || value.length > length)) {
        errors.code = isSingleName ? 'Name_invalid_length': name+'_invalid_length'
      } else if (validateAllNames(value,isMiddleName,name) && !validateNameDuplicateChar(value,isMiddleName)) {
        errors.code = /\s/.test(value) ? 'Name_invalid' : 'Name_invalid_Duplicate'
      } else if (!validateAllNames(value,isMiddleName,name) || !namePatternValidate(value,name)) {
        errors.code = 'Name_invalid'
      }
    }
  }
  
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateAllNames = (nameValue, isMiddleName, name, allowNull = false) => {
  if (allowNull && !nameValue) {
      return true;
  } else if (nameValue == null) {
      return false;
  }
  const validNamePattern = validateNameForLicense(nameValue, isMiddleName, name);
  return validNamePattern;
}

const validateNameForLicense = (nameValue = '', isMiddleName, name) => {
  if (nameValue.length > 1) {
      return name=='familyName' ? IDV_FAMILY_NAME_STRICT_PATTERN.test(nameValue) : IDV_NAMES_STRICT_PATTERN.test(nameValue);
  } else if (isMiddleName && nameValue.length === 1) {
      return nameValue[0] === '.' || nameValue[0] === '-' || nameValue[0].match('^[a-zA-Z]');
  } else if (!isMiddleName && nameValue.length === 1) {
      return nameValue[0].match('^[a-zA-Z]')
  }
  return true;
}

const validateNameDuplicateChar=(nameValue = '', isMiddleName)=> {
    const validNamePattern = validateNamePattern(nameValue);
    if (nameValue.length > 1) {
        return IDV_NAMES_3_CONSECUTIVE_DUPLICATE_CHAR.test(nameValue) && validNamePattern;
    } else if (isMiddleName && nameValue.length === 1) {
      return nameValue[0] === '.' || nameValue[0] === '-' || nameValue[0].match('^[a-zA-Z]');
    } else if (!isMiddleName && nameValue.length === 1) {
        return nameValue[0].match('^[a-zA-Z]')
    }
    return false;
}

const validateNamePattern = (nameValue)=> {
  const containsValidSequence = !INVALID_SEQEUNCE_PATTERN.test(nameValue);
  const containsValidToken = !INVALID_TOKEN_PATTERN.test(nameValue);
  return containsValidSequence && containsValidToken;
}

const isValidDate = (dateString) => {
  const dateformat = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  if (!dateString.match(dateformat)) {
    return false;
  }

  const [day, month, year] = dateString.split('/').map(Number);

  if (year === 0 || month === 0 || month > 12 || day > 31 || day === 0) {
    return false;
  }

  const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 1 || month > 2) {
    return day <= ListofDays[month - 1];
  } else if (month === 2) {
    const isLeapYear = (!(year % 4) && year % 100) || !(year % 400);
    return isLeapYear ? day <= 29 : day <= 28;
  }

  return true;
};

const changeDateFormat = (dateString) => {
  let operator = dateString.split('/');
  let newDate = '';
  let datepart = [];
  if (operator.length > 1) {datepart = dateString.split('/');}
  let day= parseInt(datepart[0]);      
  let month = parseInt(datepart[1]);      
  let year = parseInt(datepart[2]);
  newDate = month + "/" + day + "/" + year;
  return newDate
} 

const checkDOB = (dob) => {
  const currentDate = moment();
  const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const minYears = '10';
  const maxYears = '120';
  const maxDate = currentDate.clone().subtract(minYears, 'years').format('YYYY-MM-DD');
  const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
  if (!isEmpty(dob) && !moment(formattedDate).isBetween(minDate, maxDate, undefined, [])) {
      return false;
  }
  return true;
} 

const isMaximumYear = (dob)=> {
    const currentDate = moment();
    const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const maxYears = '120';
    const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
    if (!isEmpty(dob) && !moment(formattedDate).isSameOrBefore(minDate)) {
        return false;
    }
    return true;
}

const isMinimumYear = (dob)=> {
    const currentDate = moment();
    const formattedDate = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const maxYears = '120';
    const minDate = currentDate.clone().subtract(maxYears, 'years').format('YYYY-MM-DD');
    if (moment(formattedDate).diff(minDate, 'days') < 0) {
        return false;
    }
    return true;
}

const validateDOB = (value) => {
  let errors = {};
  if (isEmpty(value)) {
    errors.code = 'DOB_empty'
  } else if (!isValidDate(value)) {
    errors.code = 'DOB_invalid'
  } else if (!isMinimumYear(value)) {
    errors.code = 'DOB_MaxError'
  } else if (!checkDOB(value)) {
    errors.code = 'DOB_MinError'
  }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateImmiCardNumber = (cardNumber) => {
  if(/\s/.test(cardNumber)){
    return false;
  }
  const sanitizedCardNumber = cardNumber.replace(/\s/g, '');
  if (!IMMICARD_PATTERN.test(sanitizedCardNumber)) {
    return false;
  }
  return true
}

const validateLicenseNumber = (licenseNumber, stateSelected = '') => {
  if(/\s/.test(licenseNumber)){
    return false;
  }
  const sanitizedLicenseNumber = licenseNumber.replace(/\s/g, '');
  
  switch (stateSelected) {
    case 'QLD':
      return QLD_DOC_NUM.test(sanitizedLicenseNumber);
    case 'TAS':
      return TAS_DOC_NUM.test(sanitizedLicenseNumber);
    case 'NSW':
      return NSW_DOC_NUM.test(sanitizedLicenseNumber);
    case 'SA':
      return SA_DOC_NUM.test(sanitizedLicenseNumber);
    case 'ACT':
      return ACT_DOC_NUM.test(sanitizedLicenseNumber);
    case 'NT':
      return NT_DOC_NUM.test(sanitizedLicenseNumber);
    case 'VIC':
      return VIC_DOC_NUM.test(sanitizedLicenseNumber);
    case 'WA':
      return WA_DOC_NUM.test(sanitizedLicenseNumber);
    default:
      break;
  }
  return true;
}

const validateLicenseCardNumber = (dlCardNumber, stateSelected) => {
  switch (stateSelected) {
    case 'QLD':
      return QLD_CARD_NUM.test(dlCardNumber);
    case 'TAS':
      return TAS_CARD_NUM.test(dlCardNumber);
    case 'NSW':
      return NSW_CARD_NUM.test(dlCardNumber);
    case 'SA':
      return SA_CARD_NUM.test(dlCardNumber);
    case 'ACT':
      return ACT_CARD_NUM.test(dlCardNumber);
    case 'NT':
      return NT_CARD_NUM.test(dlCardNumber);
    case 'VIC':
      return VIC_CARD_NUM.test(dlCardNumber);
    case 'WA':
      return WA_CARD_NUM.test(dlCardNumber);
    default:
      break;
  }
  return true;
}

const validatePassport=(passportNumber)=> {
    if (passportNumber === null) {
        return false;
    }
    const sanitizedPassportNumber = passportNumber.replace(/\s\s+/g, '');
    if (!AUS_PASSPORT_NUM.test(sanitizedPassportNumber)) {
        return false;
    }
    return true;
}

const validateInternationalPassport=(passportNumber)=> {
    if (passportNumber === null) {
        // jshint ignore:line
        return false;
    }
    const sanitizedPassportNumber = passportNumber.replace(/\s\s+/g, '');
    if (!INT_PASSPORT_NUM.test(sanitizedPassportNumber)) {
        return false;
    }
    return true;
}

const validateMedicare=(medicareNumber)=> {
    if (medicareNumber == null) {
        return false;
    }
    const sanitizedMedicareNumber = medicareNumber.replace(/\s\s+/g, '');
    if (!sanitizedMedicareNumber.match('^[0-9]{10}$')) {
        return false;
    }
    return true;
}

const validateDocNumber = (value, values, name, idType) => {
  let errors = {};
  if (name === "documentNumber" && idType === DRIVER_LICENECE) {
    if (isEmpty(value)) {
      errors.code = 'Required_Field'
    } else if (!validateLicenseNumber(value,values.stateOfIssue)) {
      errors.code = 'DL_'+values.stateOfIssue+'_invalid';
    }
  } else if (name === "cardNumber") {
    if (isEmpty(value)) {
      errors.code = 'Required_Field'
    } else if (idType === IMMI_CARD && !validateImmiCardNumber(value)) {
      errors.code = 'DL_card_invalid'
    } else if (idType !== IMMI_CARD && !validateLicenseCardNumber(value,values.stateOfIssue)) {
      errors.code = 'DL_card_'+values.stateOfIssue+'_invalid'
    } 
  } else if (name === "documentNumber") {
    if (isEmpty(value)) {
      errors.code = 'Required_Field'
    } else if (idType === AUS_PASSPORT && !validatePassport(value)) {
      errors.code = 'PP_Num_AUS_invalid'
    } else if (idType === INT_PASSPORT && !validateInternationalPassport(value)) {
      errors.code = 'PP_Num_INT_invalid'
    }  else if (idType === MEDICARE_CARD && !validateMedicare(value)) {
      errors.code = 'MC_invalid'
    } 
  } 
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkDOE = (doe,idType) => {
  const currentDate = moment();
  const formattedDate = moment(doe, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const minYears = (idType===AUS_PASSPORT || idType==INT_PASSPORT) ? 3 : 0;
  const maxYears = '10';
  const minDate = currentDate.clone().subtract(minYears, 'years').format('YYYY-MM-DD');
  const maxDate = currentDate.clone().add(maxYears, 'years').format('YYYY-MM-DD');
  if (!isEmpty(doe) && !moment(formattedDate).isBetween(minDate, maxDate, undefined, [])) {
      return false;
  }
  return true;
} 

const validateExpireDate = (expireDate, idType, skipDay = false) => {
  let minYears = (idType===AUS_PASSPORT || idType==INT_PASSPORT) ? 3 : 0
  const currentDate = skipDay ? moment().format('YYYY-MM') : moment().subtract(minYears,'years').format('YYYY-MM-DD');
  const timeData = skipDay ? moment(expireDate, 'MM/YYYY').format('YYYY-MM') : moment(expireDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const valid = skipDay ? moment(timeData).isAfter(currentDate) : moment(timeData).isSameOrAfter(currentDate);
  if (!isEmpty(expireDate) && !valid) {
    return false;
  }
  return true;
}

const validateDOE = (value, idType,values) => {
  let medicGreanCard = values.cardColor && values.cardColor === "G" ? "G_" :"";
  let errors = {};
  if(idType===MEDICARE_CARD && values.cardColor==''){
    errors.code = 'MC_DOE_invalid';
  } 
  else if (isEmpty(value)) {
    errors.code = 'Required_Field'
  } else if (!isValidDate(value)) {
    errors.code = medicGreanCard+'Date_invalid'
  } else if (!validateExpireDate(value,idType)) {
    errors.code = (idType===AUS_PASSPORT || idType==INT_PASSPORT) ? 'DOE_PP_pastExpiry' : 'DOE_pastExpiry'
  }
  // else if (!checkDOE(value,idType)) {
  //   errors.code = 'DOE_postExpiry'
  // }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkForValue = (value, name) => {
  let errors = {};
  let eCode = "";
  if (name === "idType") {
    eCode = "Required_idType"
  } else if (name === "cardColor") {
    eCode = "Required_cardColor"
  } else if (name === "stateOfIssue") {
    eCode = "Required_stateOfIssue"
  } else if (name === "issuingCountry") {
    eCode = "Required_issuingCountry"
  } else {
    eCode = "Required_Field";
  }
  if (isEmpty(value)) {
    errors.code = eCode
  }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const checkCardNumber = (value, values, cardType, name) => {
  let errors = {};
  let regEx = /^(?=.*[A-Za-z0-9])(?=.*[0-9])[A-Za-z0-9]+$/;
  let eCode = "Required_documentNumber";
  if (value.length === 0) {
    if (name === "licenceNumber") {
      if (values.idType === DRIVER_LICENECE) {
        eCode = "Required_documentNumber_DL";
      } else if (values.idType === "Medicare Card" || cardType === "medic") {
        eCode = "Required_documentNumber";
      } else {
        eCode = "Required_documentNumberPP";
      }
    } else {
      eCode = "Required_cardNumber";
    }
    errors.code = eCode;
  } else if (value.length > 0) {
    if (/^0*$/.test(value)) {
      errors.code = 'Invalid_Card'
    } else if (cardType === "medic") {
      if (value.trim().length !== 10 || !/^[0-9]+$/.test(value)) {
        errors.code = 'Invalid_MedicCard'
      }
    } else if (value.trim().length < 6 || value.trim().length > 15) {
      errors.code = 'Invalid_Card'
    } else if (!/[A-Za-z0-9]/.test(value) || !/\d/.test(value)) { 
      errors.code = 'Invalid_Card'
    } else if (!regEx.test(value) && !/^[0-9]*$/.test(value)) {
      errors.code = 'Invalid_Card'
    }
  }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}


const isNameValid = (value) => {
  let isValid = true;
  if (!value || value.length === 0) { 
    isValid = false;
  } else if (value.trim().length < 2) {
    isValid = false;
  } else if (!/[a-zA-Z]/.test(value)) {
    isValid = false
  } else {
    isValid = /^[a-zA-Z'.-\s]+$/.test(value)
  }
  return isValid
}

const validNamesMatch = (values,idType) => {
  let isPassport = (idType && idType === "AUSTRALIAN PASSPORT") ? true : false;
  let fn = values.firstName ? values.firstName : '';
  let ln = values.familyName ? values.familyName : '';
  if (isPassport && isNameValid(ln)) {
    return false
  } else if ((!isNameValid(fn) && !isNameValid(ln)) || (isNameValid(fn) && !isNameValid(ln)) || (!isNameValid(fn) && isNameValid(ln))) {
    return true
  } else {
    return false
  }
}

const validateNames = (value) => {
  let errors = {};
  if (value.length > 0 && !isNameValid(value)) {
    errors.code = 'Invalid_Name'
  }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

// validate DOB is not lessthan 18years to current date
const validateDateOfBirth = (dateString, over10 = false, Under_18 = false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let day= parseInt(datepart[0]);      
  let month = parseInt(datepart[1]);      
  let year = parseInt(datepart[2]);
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  let isValid = true;
  // let a = (new Date() - new Date(year, month - 1, day)) / (365.25 * 24 * 60 * 60 * 1000);
  if (new Date(year+125, month - 1, day) < new Date(y,m,d)) {
    isValid = false;
  } else if (over10 && new Date(year + 10, month - 1, day) > new Date(y,m,d)) {
    isValid = false;
  } else if(Under_18 && new Date(y,m,d) < new Date(year + 18, month - 1, day)){
    isValid = false;
  }
  return isValid;
}

// validate DOE is not lessthan today
const validateDateOfExpiry = (dateString, isDL = false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let day= parseInt(datepart[0]);
  let month = parseInt(datepart[1]);
  let year = parseInt(datepart[2]);
  let isValid = true;
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  if (new Date(year, month - 1, day) < new Date(y, m, d)) {
    isValid = false;
  }
  if (isDL === "s" && new Date(year - 10, month - 7, day) > new Date(y, m, d)) {
    isValid = false;
  }
  if (isDL === "n" && new Date(year - 10, month - 1, day) > new Date(y, m, d)) {
    isValid = false;
  }
  return isValid;
}
// validate DOE is not lessthan today
const positionNumber = (value) => {
  let errors = {};
  if (value.length === 0) {
    errors.code = 'Required_PN'
  } else if (!/[0-9]/g.test(value)) {
    errors.code = 'Invalid_Pnum'
  } else if (!/^([1-9])$/.test(value)) {
  // } else if (!/^([1-9]|10|11)$/.test(value)) {
    errors.code = 'Invalid_Pnum'
  }
  errors.code = errors.code;
  errors.msg = clientErrors[errors.code];
  return errors;
}

const validateMedic = (dateString) => {
  let dateformat = /^([0-9]{2})\/([0-9]{4})$/;
  if (dateString.match(dateformat)) {
    let operator = dateString.split('/');
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split('/');
    }
    if(datepart[0].length!==2 || datepart[1].length!==4) return false
    let month = parseInt(datepart[0]);
    let year = parseInt(datepart[1]);
    if (year < 1900) return false
    if (month > 12) {
      return false;
    }
  } else {
    return false;
  }
  return true;  
}

const validateMedicYear = (dateString) => {
  let dateformat = /^([0-9]{2})\/([0-9]{4})$/;
  if (dateString.match(dateformat)) {
    let operator = dateString.split('/');
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split('/');
    }
    if(datepart[0].length!==2 || datepart[1].length!==4) return false
    if(parseInt(datepart[0]) === 0 || parseInt(datepart[1]) === 0 || parseInt(datepart[0]) > 12) return false
  } else {
    return false;
  }
  return true;  
}

const validateDateOfExpiryMedic = (dateString, limitYear = 10,flag=false) => {
  let operator = dateString.split('/');
  let datepart = [];
  if (operator.length > 1) {
    datepart = dateString.split('/');
  }
  let month = parseInt(datepart[0]);
  let year = parseInt(datepart[1]);
  let isValid = true;
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const d = new Date().getDate();
  if (new Date(year, month-1,d) < new Date(y,m,d)) {
    isValid = false;
  }
  if(flag && new Date(year - limitYear, month - 1, d) > new Date(y, m, d)) {
    isValid = false;
  }
  return isValid;  
}


export default validations;