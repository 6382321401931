
import React, { useEffect, useState,useRef } from 'react';
import {TextStyle} from "@able/react";
import CustomSpacing from "../components/Spacing";
const Loader = ({ type, title,desc,className }) => {
    const [loadingText, setLoadingText] = useState("Loading...")
    const statusRef = useRef(null);
    useEffect(() => { 
        if (statusRef && statusRef.current !== null) {
            statusRef.current.focus()
        }
    },[statusRef])
    useEffect(() => {
        if (title)
            setLoadingText(title)
    },[title])
    return(
        <div className={`${className ? className : ''} spinner-box`}>
            <CustomSpacing top="spacing10x"/>
            <CustomSpacing top="spacing5x" bottom="spacing5x">
                <div>
                <div className="round-spinner_new">
                    <svg className="t-loader__spinner1" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                    <svg className="t-loader__spinner2" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#0099F8" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                    <svg className="t-loader__spinner3" width="30px" height="30px" viewBox="0 0 70 70"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle stroke="#1A5199" strokeWidth="8" className="path" fill="none" cx="35" cy="35" r="30"></circle>
                    </svg>
                </div>
                <CustomSpacing top="spacing3x" />
                {
                    type && type === "focus" ?
                    <div tabIndex={-1} ref={statusRef}>
                        <TextStyle alias="HeadingB">{loadingText}</TextStyle>
                        <CustomSpacing top="spacing2x"/>
                        { desc ?
                            <TextStyle>{desc}</TextStyle> : 
                            <TextStyle>
                                This may take up to 10 seconds.<br/>Please don't close the window.
                            </TextStyle>
                        }
                    </div>
                    :
                    <div role="alert">
                        <TextStyle alias="HeadingB">{loadingText}</TextStyle>
                        <CustomSpacing top="spacing2x"/>
                        { desc ?
                            <TextStyle>{desc}</TextStyle> : 
                            <TextStyle>
                                This may take up to 10 seconds.<br/>Please don't close the window.
                            </TextStyle>
                        }
                    </div>
                }
                </div>
            </CustomSpacing>
        </div>
    )
}

export default Loader;