
import React, { useEffect, useState } from 'react';
import {MessageInline} from "@able/react";
import CustomSpacing from "./Spacing";
import moment from 'moment-timezone';
const GlobalBanner = ({ pageCID = false, pageVerify = false }) => {
    const [bambooKeys, setBambooKeys] = useState({});
    const [outageDisplay,setOutageDisplay] = useState(false);
    const BAMBOO_CID_KEYS = {
        notify : "bamboo_CID_NOTIFICATION_DISPLAY",
        title:"bamboo_CID_NOTIFICATION_TITLE",
        message:"bamboo_CID_NOTIFICATION_MESSAGE",
        startTime:"bamboo_CID_NOTIFICATION_START_TIME",
        endTime:"bamboo_CID_NOTIFICATION_END_TIME",
        variant:"bamboo_CID_NOTIFICATION_VARIANT",
        link:"bamboo_CID_NOTIFICATION_LINK",
        linkText:"bamboo_CID_NOTIFICATION_LINK_TEXT",
    }
    const BAMBOO_DVS_KEYS = {
        notify:"bamboo_DVS_NOTIFICATION_DISPLAY",
        title:"bamboo_DVS_NOTIFICATION_TITLE",
        message:"bamboo_DVS_NOTIFICATION_MESSAGE",
        startTime:"bamboo_DVS_NOTIFICATION_START_TIME",
        endTime:"bamboo_DVS_NOTIFICATION_END_TIME",
        variant:"bamboo_DVS_NOTIFICATION_VARIANT",
        link:"bamboo_DVS_NOTIFICATION_LINK",
        linkText:"bamboo_DVS_NOTIFICATION_LINK_TEXT"
    }
    
    useEffect(()=>{
        if(pageCID){
            setBambooKeys(BAMBOO_CID_KEYS);
        }
        if(pageVerify){
            setBambooKeys(BAMBOO_DVS_KEYS);
        }
    },[]);

    useEffect(()=>{
        if(Object.keys(bambooKeys).length>0){
        if(bambooKeys.notify === "true" && bambooKeys.startTime !="" && bambooKeys.endTime!=""){
            const currentDate = moment().tz('Australia/Melbourne').format('YYYY-MM-DDTHH:mm');
            if (moment(currentDate).isSameOrAfter(moment(bambooKeys.startTime)) &&
                moment(currentDate).isSameOrBefore(moment(bambooKeys.endTime))) {
                setOutageDisplay(true)
                }
            }
        }
    }, [bambooKeys]);
    
    if(outageDisplay){
        return(
            <CustomSpacing top="spacing3x">
                <MessageInline
                    description={bambooKeys.message}
                    variant={bambooKeys.variant}
                    linkText={bambooKeys.linkText}
                    anchorUrl={bambooKeys.link}
                    developmentUrl={window.location.origin + "/able-sprites.svg"}
                />
            </CustomSpacing>
        )
    }
    return <div></div>;
}

export default GlobalBanner;