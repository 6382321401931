import {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Column, InlineLink, TextStyle, Picto, SomethingWentWrong104 } from '@able/react';
import PageContent from "../PageContent";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import CustomSpacing from "../../components/Spacing";

const request_message_link = 'https://www.telstra.com.au/contact-us';
export default function PageError(props) {
  const navigation = useNavigate();
  const titleRef = useRef(null);
  const [titleText,setTitleText] = useState("Something went wrong");
  const {commonError,isPageLoaded} = useSelector((state) => state.verifyIdentify);
  
  useEffect(() => { 
    const ariaHidden = document.getElementById("root");
    ariaHidden.hasAttribute("aria-hidden") && ariaHidden.removeAttribute("aria-hidden");
    document.querySelector("body").style.overflow = "auto";
    if (isPageLoaded) {
      navigation("/")
    }
  },[])

  useEffect(() => {
    if (commonError && commonError.heading) {
      setTitleText(commonError.heading);
    }
    
    updateAnalyticsData(titleText, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "technical issue",
        "eventCategory": (commonError && commonError.code) ? commonError.code : "err_default",
        "eventName": (commonError && commonError.heading) ? commonError.heading : titleText
      },
      "attributes": {}
    })
  }, [commonError]);
  
  return (
    <PageContent title={titleText} className={"whiteBg"}>
      <Grid>
        <Column cols={12}>
          <CustomSpacing bottom="spacing4x" className="errorImgBlock">
            <Picto theme="blue">
              <SomethingWentWrong104 />
            </Picto>
          </CustomSpacing>
          <PageTitle title={titleText} ref={titleRef} focusTitle={true} />
          <CustomSpacing top="spacing3x" bottom="spacing1x">
            <TextStyle>
              { commonError.content1 ? 
              <InlineLink>
                <span dangerouslySetInnerHTML={{__html:`${commonError.content1}`}} />
              </InlineLink>
              : 
              <InlineLink>
              <TextStyle>
                We are unable to complete your request. Please try again or &nbsp;
                <a className="cnsbColor" rel="noreferrer" href={request_message_link} target="_blank">message us </a> for help.
              </TextStyle>
            </InlineLink>}
              </TextStyle>
          </CustomSpacing>
        </Column>
      </Grid>
    </PageContent>
  );
}
