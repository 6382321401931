import React from "react";
import { TextStyle } from "@able/react";
import CustomSpacing from "../components/Spacing";
const getSampleImage = (cardImages,color) => {
  return (
    <React.Fragment>
      { !cardImages.type || cardImages.type=='' || (cardImages.type=='HMEDICARD' && color=='') ? <TextStyle>{cardImages.data.error}</TextStyle> :
        cardImages.data.docList.map((el, index) => {
          if (el.id === cardImages.type) {
            return (
              <React.Fragment key={index}>
                {el.info.map((text,tIndex)=> {
                  return(
                    <React.Fragment key={tIndex}>
                      <CustomSpacing top="spacing1x" key={tIndex}>
                        <TextStyle>{text}</TextStyle>
                      </CustomSpacing>
                    </React.Fragment>
                  )
                })}
                
                {
                  el.images.map((img,imgIndex) =>{
                    return(
                    <React.Fragment key={imgIndex}>
                      {img.info && 
                        <CustomSpacing top="spacing1x" key={imgIndex}>
                          <TextStyle>{img.info}</TextStyle>
                        </CustomSpacing>
                      }
                      <CustomSpacing top="spacing2x">
                        <img src={window.location.origin+'/sample/'+color+img.name+'.svg'} 
                             alt={img[color+'alt']} aria-hidden="true" focusable="false"  
                             className="img-responsive" />
                      </CustomSpacing>
                    </React.Fragment>
                    )
                  })
                }
              </React.Fragment>
            )
          }
        })
        
      }
      
    </React.Fragment>
  )
};

export default getSampleImage;
