import { createSlice } from '@reduxjs/toolkit';
import { serverErrors } from '../helpers/messages';

const initialState = {
  validateIDVResponse: {},
  IDVDocResponse: {},
  connectIdData: {},
  isPageLoaded: true,
  commonError: serverErrors["err_default"]
}

export const verifyIdentifySlice = createSlice({
  name: 'verifyIdentify',
  initialState,
  reducers: {
    saveValidateIDVResponse: (state, action) => {
      state.validateIDVResponse = action.payload
    },
    saveIDVDocResponse: (state, action) => {
      state.IDVDocResponse = action.payload
    },
    setPageLoaded: (state, action) => {
      state.isPageLoaded = action.payload
    },
    setConnectIdData: (state, action) => {
      state.connectIdData = action.payload
    },
    setCommonError: (state, action) => {
      state.commonError = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveValidateIDVResponse,saveIDVDocResponse,setPageLoaded,setConnectIdData,setCommonError } = verifyIdentifySlice.actions

export default verifyIdentifySlice.reducer