
const CustomSpacing = ({ top="", bottom="", left="", right="", children, className }) => {
  const topCss = top ? "able-pt-"+top : "";
  const bottomCss = bottom ? "able-pb-"+bottom : "";
  const leftCss = left ? "able-pl-"+left : "";
  const rightCss = right ? "able-pr-" + right : "";
  const clsName = className ? className : "";
  return(
    <div className={`fullwidth ${topCss} ${bottomCss} ${leftCss} ${rightCss} ${clsName}`}>
      {children}
    </div>
  )
}

export default CustomSpacing;