/* eslint-disable */

const getSession = (key) => {
    return (sessionStorage && sessionStorage.getItem(key) !== null) ? JSON.parse ( sessionStorage.getItem(key) ) : false;
}
export const setSession = (key, value) => {    
    return sessionStorage ? sessionStorage.setItem( key, JSON.stringify(value) ) : false;
}

export const clearAllSessions = () => {    
    sessionStorage && sessionStorage.clear();
}
export const removeSessionItem = (key) => {
    if( sessionStorage.getItem(key) )
        sessionStorage.removeItem(key);
}

export default getSession;