import React, { useEffect,useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ConfirmationDialog, useToggle} from "@able/react";
import TelstraHeader from "../TelstraLogo";
import TelstraFooter from "../TelstraFooter";
import useDocumentTitle from "../../components/useDocumentTitle";
import { getSession } from "../../helpers";
import { updateAnalyticsData,pageChange } from '../../helpers/analytics';
import config from "../../helpers/config";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSpacing from "../../components/Spacing";

const PageContent=({
  toggleLoading,
  children,
  title,
  className,
  type
}) => {
  useDocumentTitle(title);
  const navigation = useNavigate();
  const location = useLocation();
  const timeout = 60000 * 10;
  const [isIdle, setIsIdle] = useState(false);
  const [modalIsOpen1, toggleModal1] = useToggle();
  const focusOnCloseRef1 = useRef(null);
  const [hideAira, setHideAria] = useState(false);

  useEffect(() => {
    pageChange(title)
    setHideAria(true)
    window.onpopstate = () => {
      if (getSession("loadAuth") && location.pathname !== "/") {
        navigation("/")
      }
    }
  }, [title])
  
  useEffect(() => {
    if (hideAira) {
      const ariaHidden = document.getElementById("root");
      ariaHidden.hasAttribute("aria-hidden") && ariaHidden.removeAttribute("aria-hidden");
    }

    return () => {
      setHideAria(false);
    };
  },[hideAira])

  const handleOnClick = (id, eventName) => {
    setHideAria(true)
    document.querySelector("body").style.overflow = "auto";
    if (eventName) {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "click",
          "eventType": "clickTrack",
          "eventCategory": "buttonClick",
          "eventName": eventName
        }
      })
    }
    setIsIdle(false);
    reset()
    if (id === "no") { 
      toggleModal1();
    }
    if (id === "logout" || id === "yes") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = config.logoutUrl;
    }
  }
  const handleOnIdle = () => {
    window.scrollTo(0, 0);
    setIsIdle(true);
    toggleModal1();
  }

  const {
    reset
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  });

  const renderSession = () => {
    return (
      (getSession("AuthData") && !getSession("tokenExpired") && isIdle) ?
        <ConfirmationDialog
          isShowing={modalIsOpen1}
          setHideDialog={toggleModal1}
          title="Session timeout"
          description='You are being timed out due to inactivity. Please choose to stay signed in or to Logout.'
          focusOnCloseRef={focusOnCloseRef1}
          confirmButtonLabel="Close"
          confirmButtonEvents={{
            onClick: () => handleOnClick("no", "Stay Logged In")
          }}
          cancelButtonLabel="Logout"
          cancelButtonEvents={{
            onClick: () => handleOnClick("yes", "Logout")
          }}
          developmentUrl={window.location.origin + '/able-sprites.svg'}
        />
        : null
    )
  }
  
  return (
    <React.Fragment>
      <div id="page-content" className={`${className ? className : ""}`}>
        <TelstraHeader />
        {
          type && type === "connectID" ?
          <main>
              { renderSession() }
              {children}
          </main>
          :
          <main className={`page-container ${className ? className : ""}`}>
            <CustomSpacing top="spacing5x" bottom="spacing5x">
              { renderSession() }
              {children}
            </CustomSpacing>
          </main>
        }
        <TelstraFooter />
      </div>
    </React.Fragment>
  );
}

export default PageContent;
