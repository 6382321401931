import {useState, useEffect, useRef} from "react";
import { Grid, Column, TextStyle, Picto, SomethingWentWrong104, InlineLink } from '@able/react';
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import CustomSpacing from "../../components/Spacing";
import TelstraHeader from "../TelstraLogo";
import TelstraFooter from "../TelstraFooter";
import initAnalytics from '../../helpers/analytics';
import useDocumentTitle from "../../components/useDocumentTitle";

const request_message_link = 'https://www.telstra.com.au/contact-us';
export default function PageRestricted(props) {
  const titleRef = useRef(null);
  const [titleText] = useState("Something went wrong");
  useDocumentTitle(titleText);
  
  useEffect(() => {
    if (!window.digitalData) initAnalytics();
    updateAnalyticsData(titleText, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "technical issue",
        "eventCategory": "",
        "eventName": "Access restricted"
      },
      "attributes": {}
    })
  }, []);
  
  return (
    <div id="page-content">
      <TelstraHeader />
      <main className="page-container">
        <CustomSpacing top="spacing5x" bottom="spacing5x">
          <Grid>
            <Column cols={12}>
              <CustomSpacing bottom="spacing4x" className="errorImgBlock">
                <Picto theme="blue">
                  <SomethingWentWrong104 />
                </Picto>
              </CustomSpacing>
              <PageTitle title={titleText} ref={titleRef} focusTitle={true} /> 
              <CustomSpacing top="spacing3x" bottom="spacing1x">
                <InlineLink>
                  <TextStyle>
                    We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href={request_message_link} target="_blank">message us</a> for help.
                  </TextStyle>
                </InlineLink>
              </CustomSpacing>
            </Column>
          </Grid>
        </CustomSpacing>
      </main>
      <TelstraFooter />
    </div>
  );
}
