
import React, { useEffect } from 'react';

const ConnecIdBtn = ({ handleOnClick }) => {
  let isMounted = true;
  let options = {
    buttonLabel: 'Verify',
    buttonSize: 'regular',
    theme: 'dark',
    onClick: () => {
      handleOnClick("connectID", "Verify with connectID")
    }
  }
  useEffect(() => {
    if (isMounted) window.cidInitButton(options)
    isMounted = false
  },[isMounted])
  
  return <div id="cid-idp-selector-button-target"></div>
}

export default ConnecIdBtn;