import { getEnv } from '../helpers/analytics';
import { setSession } from '../helpers';
var env = getEnv(window.location.host);
env = env ? env : 'local';

const localConstants =  {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId: 'b2c-idcheck',
  clientScope: 'openid acr',
  clientRoot : 'http://localhost:3000/',
  logout : 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=http://localhost:3000/',
  apiRoot : 'https://fn1.myid-nonprod.telstra.com/identity/',
}

const uatConstants = {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId : 'b2c-idcheck',
  clientScope: 'openid acr',
  clientRoot : 'https://fn1.nonprod.idcheck.telstra.com/',
  logout : 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn1.nonprod.idcheck.telstra.com',
  apiRoot : 'https://fn1.myid-nonprod.telstra.com/identity/'
}

const fn2Constants = {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId : 'b2c-idcheck',
  clientScope: 'openid acr',
  clientRoot : 'https://fn2.nonprod.idcheck.telstra.com/',
  logout : 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn2.nonprod.idcheck.telstra.com',
  apiRoot : 'https://fn3.myid-nonprod.telstra.com/identity/'
}

const fn3Constants = {
  stsAuthority : 'https://fn1.myid-nonprod.telstra.com/identity',
  clientId : 'b2c-idcheck',
  clientScope: 'openid acr',
  clientRoot : 'https://fn3.nonprod.idcheck.telstra.com/',
  logout : 'https://fn1.myid-nonprod.telstra.com/identity/idp/startSLO.ping?TargetResource=https://fn3.nonprod.idcheck.telstra.com',
  apiRoot : 'https://fn3.myid-nonprod.telstra.com/identity/'
}

const prodConstants = {
  stsAuthority : 'https://myid.telstra.com/identity',
  clientId : 'b2c-idcheck',
  clientScope: 'openid acr',
  clientRoot : 'https://idcheck.telstra.com/',
  logout : 'https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=https://idcheck.telstra.com',
  apiRoot : 'https://myid.telstra.com/identity/',
}

//const constants = env === 'local' ? localConstants : env === 'uat' ? uatConstants : env === 'fn2' ? fn2Constants : prodConstants
const constants = env === 'local' ? localConstants : env === 'uat' ? uatConstants : env === 'fn2' ? fn2Constants : env === 'fn3' ? fn3Constants : prodConstants
setSession("authority", constants.stsAuthority);

export default { ...constants }
