export function isAndroid(userAgent) {
    const ua = userAgent || navigator.userAgent;
    return !!ua.match(/(Android)/i);
}

export function isMobile(userAgent) {
    const ua = userAgent || navigator.userAgent;
    return !!ua.match(/(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i);
}

export function isIOS(userAgent) {
    const ua = navigator.userAgent;
    const isSafari = !!ua.match(/Version\/[\d.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
    return isSafari && iOS;
}

export function getDeviceType(){
    const ua = navigator.userAgent;
    let deviceType = "desktop";
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) { deviceType = "tablet"; }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) { deviceType = "mobile"; }
    return deviceType
}