import React, {useEffect} from 'react';
import { TextStyle } from "@able/react";
import CustomSpacing from "../../components/Spacing";

const PageTitle = React.forwardRef((props, ref) => {
  const { title, top = '', bottom = '', focusTitle, className='' } = props;
  useEffect(() => {
    if(focusTitle){
      if (ref && ref.current !== null) {
        ref.current.focus();
      }
    }
  }, [ref,focusTitle]);
  return (
    <CustomSpacing className={className} top={top} bottom={bottom}>
      <TextStyle alias="HeadingB" element="h1">
        <span ref={ref} tabIndex="-1">{title}</span>
      </TextStyle>
    </CustomSpacing>
  );
});
export default PageTitle;