
import React, { useEffect } from 'react';
import { isMobile } from "../helpers/deviceType";
import config from '../helpers/config';

const CidSelector = ({ cidOnClick,claimsList, cidParticipants, redLogo,focusOnCloseRef }) => {
  let isMounted = true;
  let banksList = config.hideBanks;

  const removeListItemByContent=(itemName)=> {
    const ul = document.getElementById('cid-idp-selector-popup-participants');
    const items = ul.getElementsByTagName('li');
    for (let i = 0; i < items.length; i++) {
      let textContent = items[i].textContent.trim();
      // console.log("textContent=",textContent)
      if (textContent === itemName) {
          ul.removeChild(items[i]);
          break;
      }
    }
  }

  const removeAppOnlyIDPs = () => {
    // isMobile() &&
    banksList && banksList.length && banksList.map((el) =>
      removeListItemByContent(el)
    )
  }

  let options = {
    onProceed: function (authorisationServerId) {
      cidOnClick(authorisationServerId)
    },
    onError: function () {
      // console.log('Error loading CID IDP Selector')
    },
    buttonLabel: 'Verify',
    rpLogoUrl: redLogo,
    rpLogoAlt: "Telstra Logo",
    participantsUri: cidParticipants,
    claims: claimsList,
    rpDataSharingDescription: "Your details will be shared for the purpose of verifying your identity with Telstra",
    inline: true,
    theme: "light",
    onParticipantsLoaded: removeAppOnlyIDPs
  }
  
  useEffect(() => {
    if (isMounted) window.cidInitIdpSelector(options)
    isMounted = false
  },[isMounted])
  
  return <div ref={focusOnCloseRef} id="cid-idp-selector"></div>
}

export default CidSelector;