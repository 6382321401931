import getSession from "../helpers/sessions";

export function getFetchConfigForDocument(token=false,parToken=false) {
  const access_token = getSession('AuthData');
  const cid_store = getSession("cid_store");
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': access_token ? `Bearer ${access_token.access_token}` : `Bearer ${token}`,
  }
  if (parToken && cid_store) {
    headers = {
      ...headers,
      "auth_server_id": cid_store.authorisationServerId,
      "originalState": cid_store.state,
      "code_verifier": cid_store.codeVerifier,
      "nonce": cid_store.nonce,
      "scanType": "Digital",
      "clientCode": getSession("clientCode"),
      // "iss": getSession("cid_iss"),
      "referenceId": cid_store.referenceId
    }
  }
  return {
    mode: "cors",
    credentials: 'same-origin',
    headers: headers
  }
}

function handleCSRFHeader(response) {
  const csrfHeaderKey = response.headers.get("X-CSRF-HEADER");
  if (csrfHeaderKey) {
    _csrtHeaderKey = csrfHeaderKey;
    _csrtHeaderValue = response.headers.get(csrfHeaderKey);
  }
  return response;
}

export function handleRawResponse(response) {
  handleCSRFHeader(response);

  if (response.ok || (response.status >= 400 && response.status < 500)) {
    return response.json();
  }

  return Promise.reject(new Error(`${response.status} ${response.statusText}`));
}

export function handleJsonResponse(response, isRawResponseOk) {
  if (response.message && !isRawResponseOk) {
    throw new Error(response.message);
  } else {
    return response;
  }
}




